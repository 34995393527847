// endpoint:

// https://flztijqtda.execute-api.us-west-1.amazonaws.com/prod

// payload:

// shop=lamanso.myshopify.com
// notification[product_no]=6598450806958
// notification[quantity_required]=1
// notification[accepts_marketing]=false
// notification[customer_utc_offset]=-19800
// notification[product_title]=La Jefa
// notification[product_vendor]=LaManso
// notification[product_handle]=la-jefa
// notification[email]=thoratabhishek735@gmail.com
// variant[variant_no]=39465918398638
// variant[variant_title]=Default Title
// variant[sku]=123

import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { body } from 'components/Theme/Copy';
import { ThemeButton } from 'components/Theme/Button';
import FormTag from 'components/Page/Forms/Form';

const OOSContainer = styled.div`
  position:relative;
`;

const OOSForm = styled.div`
  display:${p => p.hide ? 'none' : 'block'};
`;

const OOSButton = styled(ThemeButton)`
  position:absolute;
  top:-3.9rem; // the height of the buy button
  left:16.2rem; // just the width of the buy button
  margin-left:1.5rem;
  display:${p => p.open ? 'none' : 'block'};

  ${media.lessThan("tablet")`
    margin-left:3rem;
  `};
`;


const OOSCopy = styled(body)`
  font-size:1.4rem;
  margin:2rem 0 1rem 0;
  color:${p => p.theme.cardTextColor};
  line-height:2rem;
`;

const EmailButton = styled(ThemeButton)`
  font-size:1rem;
  padding:1rem;
  position:relative;
  margin-left:1rem;
  ${media.lessThan("tablet")`
    margin-top:1rem;
    margin-left:0;
    font-size:1rem;
  `};
`;

const EmailTextField = styled.input`
  font-size:1.4rem;
  line-height:3.2rem;
  border:0.1rem solid ${p => p.theme.brandSecondaryLinks};
  text-indent:0.5rem;
  position:relative;
  top:0.2rem;
  margin-right:0.4rem;
  border-radius:0.5rem;

  ${media.between("tablet", "desktop")`
    margin-right:0;
  `};

  ${media.lessThan("tablet")`
    margin-top:1rem;
  `};
`;


const OOS = ({ productId, variantId, fullTitle, image, variantSku }) => {
  const [open, setOpen] = useState(false);

  return (
    <OOSContainer>
      <OOSButton onClick={() => setOpen(true)} open={open}>Notify Me</OOSButton>

      <OOSForm hide={!open}>
        <OOSCopy>Join the waitlist to be notified when we restock.</OOSCopy>

        <FormTag name="OOS Notification" successPage="/email-subscribe-confirmation">
          <EmailTextField type="email" name="email" placeholder="Email Address" required />
          <EmailButton>Notify Me</EmailButton>
          <input type="hidden" name="productId" value={productId} />
          <input type="hidden" name="variantId" value={variantId} />
          <input type="hidden" name="fullTitle" value={fullTitle} />
          <input type="hidden" name="image" value={image} />
          <input type="hidden" name="variantSku" value={variantSku} />
        </FormTag>
      </OOSForm>
    </OOSContainer>
  )
}


export default OOS

