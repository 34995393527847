import { css } from 'styled-components';
import { btoa } from 'b64-lite'

export const productCss = css`
  .shopify-buy__option-select-wrapper {
    margin-bottom:2rem;
  }

  .shopify-buy__option-select {
    background-color:${p => p.theme.cardTextColorDark};
    padding:1rem 2rem;
    color:${p => p.theme.cardBgColor};
    margin-right:1rem;
    text-transform:uppercase;
    font-size:1rem;
    cursor:pointer;
    letter-spacing:0.1em;
  }

  .shopify-buy__option--selected {
    background-color:${p => p.theme.cardBgColorLight};
    color:${p => p.theme.cardTextColor};
  }

  .shopify-buy__btn {
    margin-top:1.5rem;
  }
`;


const productOptions = (ui, onVariantSelected, theme, isDark, ecommerce) => {
  return {
    option: {
      templates: {
        option: '' +
          '<div class="{{data.classes.option.wrapper}}">' +
            '<div>' +
              '{{#data.values}}' +
              '<button {{#data.styleAttr}} {{name}} {{/data.styleAttr}} data-value="{{name}}" data-option={{data.name}} class="{{#disabled}}{{data.classes.option.optionDisabled}}{{/disabled}} {{#selected}}{{data.classes.option.optionSelected}}{{/selected}} {{data.classes.option.option}}">{{#data.optionName}}{{name}}{{/data.optionName}}</button>' +
              '{{/data.values}}' +
            '</div>' +
          '</div>'
      },
    },
    product: {
      iframe: false,
      DOMEvents: {
        'click .shopify-buy__option-select': function (evt, target) {
          var data = target.dataset;
          ui.components.product.map((product) => {
            product.updateVariant(data.option, data.value);

            // update price
            if (typeof window !== `undefined`) {
              onVariantSelected(btoa(product.selectedVariant.id))
              // document.getElementById('price').innerHTML = `$${product.selectedVariant.price.slice(0, -3)}`;
              if (ecommerce) {
                let __price = parseFloat(product.selectedVariant.price.amount)
                document.getElementById('price').innerHTML = `$${__price}`;
              }
            }
            
          })
        }
      },
      viewData: {
        optionName: function () {
          return function (text, render) {
            var key = render(text).trim();
            return key;
          }
        }
      },
      contents: {
        img: false,
        title: false,
        price: false,
        button: ecommerce,
      },
      text: {
        "button": "Add to cart"
      },
      googleFonts: [
        "Muli"
      ]
    },
    modalProduct: {
      contents: {
        img: false,
        imgWithCarousel: true,
        button: false,
        buttonWithQuantity: true
      },
      googleFonts: [
        "Muli"
      ],
      text: {
        "button": "Add to cart"
      }
    }
  }
}

export default productOptions;