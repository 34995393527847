import React from "react";
import { Blocks } from 'components/Blocks';
import { graphql } from 'gatsby'

import SEO from "components/seo";
import Layout from "components/layout";
import Shop from 'components/Page/Product/Shop';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';


const Product = ({data, pageContext}) => {
  const { contentfulProduct } = data
    
  const shopify = pageContext.shopify;
  return (
    <Layout whitePage={true}>
      <SEO data={contentfulProduct.seo} />
      <Shop data={ contentfulProduct } shopify={ shopify }  />
      
      <Blocks data={contentfulProduct.blocks} shopify={ shopify } />
    </Layout >
  )
}

export default Product

// language=GraphQL
export const pageQuery = graphql`
  query ProductQuery($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      id
      __typename
      contentful_id

      commercial
      seo {
        ...SEOFields
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      features {
        childMarkdownRemark {
          html
        }
      }
      specs {
        childMarkdownRemark {
          html
        }
      }
      shippingInfo
      warranty

      blocks { 
        ...BlockFields
      }

      variants {
        shopifyId
        images {
          gatsbyImageData(width: 800)
        }
        thumbs: images {
          gatsbyImageData(height: 60)
        }
        heroAsset {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;