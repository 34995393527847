import React, { useState } from "react";

// Styles
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h5, body } from 'components/Theme/Copy';

// Helpers
import useScrollPosition from '@react-hook/window-scroll'

// Components
import EstimatedDelivery from './EstimatedDelivery';
import BuyButton from 'components/Theme/BuyButton';


const Container = styled.div`
  position:fixed;
  bottom:0;
  background-color:${p => p.theme.cardBgColorLight};
  height:10rem;
  width:100%;
  left:0;
  right:0;
  bottom:${p => p.show ? '-0.1rem' : '-16rem'};
  display:flex;
  flex-direction:row;
  align-items:stretch;
  justify-content:space-between;
  z-index:999999;
  padding:1.5rem 4.5rem;
  transition: bottom 200ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  box-shadow:0 0 1rem 0.2rem rgba(0,0,0,0.15);

  ${media.lessThan("tablet")`
    flex-direction:column;
    height:auto !important;
    align-items:flex-start;
    padding:1.5rem;
  `};
`;

const Details = styled.div`
  display:flex;
  flex-direction: column;
  ${media.lessThan("tablet")`
    justify-content:space-between;
    align-items:center;
    
    width:100%;
    padding-bottom:1.5rem;
  `};
`;

const Actions = styled.div`
  display:flex;
  justify-content: flex-end;
  align-items:center;

  ${media.lessThan("tablet")`
    width:100%;
    justify-content: center;

    .shopify-buy__product {
      display:flex;
      justify-content: center;
    }
  `};
`;

const Price = styled(body)`
  display:flex;
  font-size:1.4rem;
  color:${p => p.theme.cardTextColor};
`;

const ProductPrice = styled(body)`
  font-size:1.8rem;
  margin-right:1rem;
  line-height:4rem;
`;

const ComparePrice = styled(body)`
  color:#999;
  text-decoration:line-through;
  font-weight:300;
  font-size:2rem;
`;

const Title = styled(h5)`
  
`;

const DeliveryEstimate = styled(EstimatedDelivery)`
  margin-top:0;
  margin-right:1.5rem;
  
  h2 {
    font-size: 1.2rem;
  }

  div {
    color:#fff !important;
  }

  ${media.lessThan("tablet")`
    display:none;
  `};
`;

const FinePrint = styled.div`
  display:inline-block;
  font-size:1rem;
  margin-left:0.5rem;
  color:#fff;
`;

const BottomBar = ({price, variant, onVariantSelected, oos}) => {
  // Hooks
  const scrollY = useScrollPosition(60 /*fps*/);

  // State
  const [shopBottomBar, toggleShopBottomBar] = useState(false);

  // Bottom Bar
  if (scrollY > 300) {
    !shopBottomBar && toggleShopBottomBar(true)
  } else {
    shopBottomBar && toggleShopBottomBar(false)
  }

  return (
    <Container show={shopBottomBar}>
      <Details>
        <Title>{variant.product.title}</Title>
        <Price>
          <ProductPrice id="price">{price}</ProductPrice>
          <ComparePrice style={{ display: variant.compareAtPrice !== undefined && variant.compareAtPrice > 0 ? 'inline-block' : 'none'}}>${(variant.compareAtPrice && variant.compareAtPrice.slice(0, -3))}</ComparePrice>
          {/* <ComparePrice style={{ display: 'inline-block' }}>{price}</ComparePrice> */}
          {/* <ComparePrice style={{ display: 'inline-block' }}>${parseFloat(variant.price)}</ComparePrice> */}
          {/* <FinePrint>*with 20% discount</FinePrint> */}
        </Price>
      </Details>
      
      <Actions>
        <DeliveryEstimate variant={variant} oos={oos} />
        <BuyButton id={ variant.product.shopifyId } priceElement={variant.price} onVariantSelected={onVariantSelected} bottomBar={true} />
      </Actions>
    </Container>
  )
}

export default BottomBar;