import React, { useState, useEffect, useContext } from "react";
import { Container, Card, flexBasis } from 'components/Theme/Grid';

// Styles
import styled, { ThemeContext } from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h3, body } from 'components/Theme/Copy';

// Helpers
import _ from 'lodash';
import { toPrice, toFullTitle, findFirstImage, affirmRefresh, affirmPriceFormat } from 'components/Helpers/ProductShopHelper';
import processHtml from 'lib/processHTML'
import ReactHtmlParser from 'react-html-parser'
import { findVariant } from 'components/Shopify/Variant';
import { getSrc } from 'components/Helpers/ImageHelper';

// Components
import OOS from './OOS';
import EstimatedDelivery from './EstimatedDelivery';
import BottomBar from './BottomBar';
import BuyButton from 'components/Theme/BuyButton';
import Button from 'components/Theme/Button';

// 3rd Party
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { MdKeyboardArrowRight } from "react-icons/md";

// Styles
const BodyCopy = styled(body)`
  line-height:2rem;
`;

const ProductWrapper = styled.div`
  background-color:${p => p.theme.white};
`;

const ProductContainer = styled(Container)`
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items: flex-start;
  padding:2.5rem 0 5rem 0;
  background-color:${p => p.theme.white};
  margin-bottom:0;
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:active {
    border:2px solid ${p => p.theme.brandPrimary} !important;
  }
  .image-gallery-icon {
    color:#000;
    filter:none;
    -webkit-filter:none;
  }
  ${media.lessThan("tablet")`
    flex-direction:column;
  `}
`;

const ImagesCard = styled(Card)`
  ${flexBasis(55)}
  justify-content:center;
  align-items:center;
  padding-left:4rem;
  margin-top:3rem;
  border-radius:0.5rem 0 0 0.5rem;
  background-color:${p => p.theme.white};
  .image-gallery {
    
  }
  .image-gallery-slide-wrapper {
    margin-bottom:3rem;
  }
  .image-gallery-slides {
    border-radius:0.5rem;
  }
  .image-gallery-svg {
    height: 60px !important;
    width:30px !important;
  }
  .image-gallery-icon {
    cursor:pointer;
  }
  .image-gallery-icon:hover {
    color:${p => p.theme.brandPrimary};
  }

  ${media.lessThan("tablet")`
    border-radius:0.5rem 0.5rem 0 0;
    padding: 4rem 3rem;
    width:100%;
    .image-gallery {
      width:100%;
    }
  `}
`;

const DetailsCard = styled(Card)`
  //background-color:${p => p.theme.brandPrimary};
  background-color:${p => p.theme.white};
  display:flex;
  border-radius:0.5rem;
  ${flexBasis(45)}
  flex-direction:column;
  align-self:flex-start;
  padding:4rem;
  ${media.lessThan("tablet")`
    padding:4rem 3rem;
  `}
`;

const Title = styled(h1)`
  color:${p => p.theme.black};
  font-size:5rem;
  margin-top:2rem;
`;

const Description = styled(BodyCopy)`
  color:${p => p.theme.black};
  font-size:1.5rem;
`;

const Affirm = styled(Description)`
  font-size:1.2rem;
  margin-bottom:1.5rem;
  display:block;
  font-weight:bold;
  color:${p => p.theme.black};
  a {
    font-weight:bold;
    text-decoration: underline;
  }
`

const SpecsAndFeatures = styled.div`
  margin:4rem 0;
`;

const Specs = styled(body)`
  font-size:1.5rem;
  
  border-top:0.1rem solid ${p => p.theme.cardBorderColor};
  color:${p => p.theme.black};

  &:last-child {
    border-bottom:0.1rem solid ${p => p.theme.cardBorderColor};
  }

  ul {
    margin-left:3rem;
    list-style-type:disc !important;

    li {
      line-height:3rem;
    }
  }
`;

const SpecHeader = styled(h3)`
  margin:0.5rem 0;
  font-size:1.55rem;
  cursor:pointer;
  user-select: none;
  font-weight:300;
  color:${p => p.theme.black};
`;

const SpecHeaderArrow = styled(MdKeyboardArrowRight)`
  font-size:2rem;
  position:relative;
  top:0.5rem;
  margin-top:-0.5rem;
  transition: transform 0.1s linear;
  transform: ${p => p.showSpecs ? `rotate(90deg)` : `rotate(0deg)`};
`;

const SpecDetails = styled.div`
  display:${p => p.showSpecs ? 'block' : 'none'};
  padding-bottom:1rem;
`;


const Price = styled(BodyCopy)`
  display:flex;
  margin:1rem 0 2rem 0;
  color:${p => p.theme.black};
`;

const ProductPrice = styled(BodyCopy)`
  font-size:2rem;
  margin-right:1rem;
  color:${p => p.theme.black};
`;

const ComparePrice = styled(BodyCopy)`
  color:#333;
  text-decoration:line-through;
  font-weight:300;
  font-size:2rem;
`;

const FinePrint = styled.div`
  display:inline-block;
  font-size:1rem;
  margin-left:0.5rem;
  color:#333;
`;

// Helper Functions
const fetchVariantAvailability = (variantId, done) => {
  if(typeof window !== `undefined`) {
    let client = window.shopifyUI.client.product.graphQLClient
    client.fetcher({query: `{ node(id: "${variantId}") { ...on ProductVariant { availableForSale } }}`}).then((v) => {
      done(v.data.node.availableForSale)
    })
  }
}

const BuildImages = (images, thumbs) => {
  var ProdImages = [];
  images.map((image, i) => (
    ProdImages.push({'original': getSrc(image), 'thumbnail': getSrc(thumbs[i])})
  ))
  return ProdImages;
}

const Shop = ({data, shopify}) => {
  const theme =  useContext(ThemeContext)
  const { specs, description, variants, features, commercial } = data;

  // State
  const [showSpecs, toggleSpecs] = useState(false);
  const [showFeatures, toggleFeatures] = useState(false);
  const [variantId, switchVariant] = useState(variants[0].shopifyId);
  const [oos, setOOS] = useState(false);

  // Find Product & Variant
  const contentfulVariant = _.find(variants, (v) => { return v.shopifyId === variantId })
  const variant = findVariant(shopify, variantId)
  
  // Check to see if OOS
  fetchVariantAvailability(variantId, (available) => {
    setOOS(!available)
  })

  // Format Texts
  const price = toPrice(parseFloat(variant.price) + "")
  const fullTitle = toFullTitle(variant)
  const firstImage = findFirstImage(contentfulVariant)  
  const affirmPrice = affirmPriceFormat(variant.price)

  // Refresh the Affirm object
  useEffect(() => {
    affirmRefresh()
  });

  return (
    <div>
      <ProductWrapper>
        <ProductContainer neumorphic gutterBottom>
          <ImagesCard noGutter size={2} sm={1}>
            <ImageGallery items={BuildImages(contentfulVariant.images, contentfulVariant.thumbs)} showPlayButton={false} showFullscreenButton={false} />
          </ImagesCard>
          
          <DetailsCard noGutter size={2} sm={1}>
            { variant && 
              <>
                <Title>{variant.product?.title}</Title>

                { !commercial && 
                  <>
                  <Price>
                    <ProductPrice id="price">{price}</ProductPrice>
                    { variant.compareAtPrice !== undefined && variant.compareAtPrice > 0 && 
                      <ComparePrice style={{ display: variant.compareAtPrice !== undefined && variant.compareAtPrice > 0 ? 'inline-block' : 'none'}}>${(variant.compareAtPrice && variant.compareAtPrice.slice(0, -3))}</ComparePrice>
                    }
                  </Price>

                  <Affirm className="affirm-as-low-as" data-amount={affirmPrice} data-page-type="product" />
                  </>
                }
                
              </>
            }

            { description && 
              <Description>{ ReactHtmlParser((description.childMarkdownRemark.html), { transform: processHtml }) }</Description>
            }

            <SpecsAndFeatures>
              { features && 
                <Specs>
                  <SpecHeader onClick={ () => toggleFeatures(!showFeatures)}><SpecHeaderArrow showSpecs={showFeatures} /> Features</SpecHeader>
                  <SpecDetails showSpecs={showFeatures}>{  ReactHtmlParser((features.childMarkdownRemark.html), { transform: processHtml }) }</SpecDetails>
                </Specs>
              }

              { specs && 
                <Specs>
                  <SpecHeader onClick={ () => toggleSpecs(!showSpecs)}><SpecHeaderArrow showSpecs={showSpecs} /> Specs</SpecHeader>
                  <SpecDetails showSpecs={showSpecs}>{  ReactHtmlParser((specs.childMarkdownRemark.html), { transform: processHtml }) }</SpecDetails>
                </Specs>
              }
            </SpecsAndFeatures>
            
            { variant && 
              <div>
                
                <BuyButton id={ variant.product.shopifyId } priceElement={variant.price} onVariantSelected={switchVariant} ecommerce={!commercial} />
                
                { commercial && 
                  <Button to="/contact">Contact Us</Button>
                }
                
                { oos && !commercial && 
                    <OOS  productId={variant.product.shopifyId}
                          variantId={variantId}
                          fullTitle={fullTitle}
                          image={firstImage}
                          variantSku={variant.sku}>Get Notified</OOS> 
                }
              </div>
            }

            { !commercial && 
              <EstimatedDelivery variant={variant} oos={oos} />
            }
          </DetailsCard>

        </ProductContainer>
      </ProductWrapper>

      { !commercial && 
        <BottomBar price={price} 
                  variant={variant} 
                  oos={oos}
                  onVariantSelected={switchVariant}
                  ecommerce={!commercial}  />
      }
    </div>
  )
}



export default Shop;
