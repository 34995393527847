import { css } from 'styled-components';
import { media } from 'components/Theme/Styles';
import { btoa } from 'b64-lite'

export const bottomBarCss = css`
  display:flex;
  flex-direction:row;
  justify-content: flex-end;
  align-items:center;
  ${media.lessThan("tablet")`
    width:100%;
  `};

  .shopify-buy__product {
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;

    ${media.lessThan("tablet")`
      justify-content:space-between;
      flex-direction: row;
      width:100%;
    `};
  }

  .shopify-buy__product__variant-selectors {
    position:relative;
  }

  .shopify-buy__option-select-wrapper {
    background-color:${p => p.theme.cardBgColorLight};
    color:${p => p.theme.cardTextColor};
    margin-right:1rem;
    padding:1.5rem 2.5rem 1.5rem 0rem;
    text-indent:1rem;
    text-transform:uppercase;
    text-overflow: ellipsis;
    outline:none;
    appearance: none;
    font-size:1.2rem;
    cursor:pointer;
    letter-spacing:0.1em;

    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, gray, gray);
    background-position:
      calc(100% - 1.3rem) calc(1.5rem + 0.2rem),
      calc(100% - 0.8rem) calc(1.5rem + 0.2rem),
      calc(100% - 2.5rem) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 2.8em;
    background-repeat: no-repeat;
  }

  .shopify-buy__option--selected {
    background-color:${p => p.theme.cardTextColorDark};
    color:${p => p.theme.cardBgColor};
  }
`;

const productOptionsBottomBar = (ui, onVariantSelected, theme, isDark) => {
  return {
    option: {
      templates: {
        option: '' +
          '<select class="{{data.classes.option.wrapper}}">' +
              '{{#data.values}}' +
              '<option {{#data.styleAttr}} {{name}} {{/data.styleAttr}} data-value="{{name}}" data-option={{data.name}} class="{{#disabled}}{{data.classes.option.optionDisabled}}{{/disabled}} {{#selected}}{{data.classes.option.optionSelected}}{{/selected}} {{data.classes.option.option}}" {{#selected}}selected{{/selected}}>{{name}}</option>' +
              '{{/data.values}}' +
          '</select>'
      },
    },
    product: {
      iframe: false,
      DOMEvents: {
        'change .shopify-buy__option-select-wrapper': function (evt, target) {
          var opt = target.options[target.selectedIndex];
          var data = opt.dataset;
          
          ui.components.product.map((product) => {
            product.updateVariant(data.option, data.value);
            
            // update price
            if (typeof window !== `undefined`) {
              onVariantSelected(btoa(product.selectedVariant.id))
              // document.getElementById('price').innerHTML = `$${product.selectedVariant.price.slice(0, -3)}`;

              let __price = parseFloat(product.selectedVariant.price);
              document.getElementById('price').innerHTML = `$${__price}`;
            }
          })
        }
      },
      viewData: {
        optionName: function () {
          return function (text, render) {
            var key = render(text).trim();
            return key;
          }
        }
      },
      contents: {
        img: false,
        title: false,
        price: false
      },
      text: {
        "button": "Add to cart"
      },
      googleFonts: [
        "Muli"
      ]
    },
    modalProduct: {
      contents: {
        img: false,
        imgWithCarousel: true,
        button: false,
        buttonWithQuantity: true
      },
      googleFonts: [
        "Muli"
      ],
      text: {
        "button": "Add to cart"
      }
    }
  }
}

export default productOptionsBottomBar;