import React, { useEffect, useRef } from 'react';

// BuyButton Options
import productOptions, { productCss } from 'components/Helpers/ShopifyBuyButtonProductOptions';
import productOptionsBottomBar, { bottomBarCss } from 'components/Helpers/ShopifyBuyButtonProductOptionsBottomBar';

// Styles
import styled from 'styled-components';
import { withTheme } from 'styled-components';
import { useStyledDarkMode } from "gatsby-styled-components-dark-mode";
import { ButtonCss } from 'components/Theme/Button';

const AddToCart = styled.div`
  .shopify-buy__btn {
    ${ButtonCss}
    padding:1.5rem 3rem;
  }

  .shopify-buy__btn-disabled {
    box-shadow:none;
  }

  ${p => p.bottomBar ? bottomBarCss : productCss };
`;

const BuyButton = ({id, theme, onVariantSelected, bottomBar, ecommerce}) => {
  const buyButton = useRef({});
  const { isDark } = useStyledDarkMode();
  const options = (bottomBar ? productOptionsBottomBar : productOptions)

  const isDarkMode = () => {
    return isDark
  }

  useEffect(() => {
    if(typeof window !== `undefined`) {
      let ui = window.shopifyUI
      
      ui.createComponent('product', {
        storefrontId: id,
        node: buyButton.current,
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: options(ui, onVariantSelected, theme, isDarkMode(), ecommerce)
      })
    }
  }, [])

  
  return (
    <AddToCart ref={buyButton} bottomBar={bottomBar} />
  )
}

export default withTheme(BuyButton);
      
